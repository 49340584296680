/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from 'styled-components';
import Header from "./header"
import "./layout.css"
import { useState } from "react"
import {checkStorageIfSetReturnOrNot, updateStorageItem} from "../Scripts/LocalStorage"
import {defaultTheme, lightTheme, darkTheme} from '../components/components-ui/ColourPallets.js';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  //I'm 100% sure I did too much here. I'll go back to this to make a less verbose
  //Theme Change - Shove this in gatsby browser and export the theming code.
  const [themeState, setThemeState] = useState(checkStorageIfSetReturnOrNot("theme", "Default"))
  const [count, setCount] = useState(checkStorageIfSetReturnOrNot("count", 0))

  const wordThemeArray = ['Default','Dark','Light'];

  const theme = returnAllThemes(themeState);

  function CountLoop(Innercount) {
    if (Innercount === 2) {
      Innercount = 0
      setCount(Innercount)
    } else {
      Innercount++
      setCount(Innercount)
    }
    return Innercount
  }
  function returnAllThemes(changeTheme){
    try {
      switch (changeTheme) {
        case "Light":
          updateStorageItem("theme", changeTheme)
          return lightTheme
  
        case "Dark":
          updateStorageItem("theme", changeTheme)
          return darkTheme
  
        case "Default":
          updateStorageItem("theme", changeTheme)
          return defaultTheme
      
        default:
          return defaultTheme
      }
    } catch (error) {
      
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Header siteTitle={data.site.siteMetadata?.title || `Portfiio`} onThemeChange = {()=>(setThemeState(wordThemeArray[CountLoop(count)]))}/>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1500,
          padding: `0 2.0875rem 1.45rem`,
          textAlign: "center",
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          <p style = {{backgroundColor: `${props => props.theme.colors.text.textColourPrimary}`}}>
          © {new Date().getFullYear()}, Built with
          {` Slimes & `}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
          </p>
        </footer>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
