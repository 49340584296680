//Local Storage - Make this an external file to import
//
export function checkStorageIfSetReturnOrNot(keyToGet, keySetDefault) {
    if (typeof window !== 'undefined') {
      //console.log('we are running on the client')

      if (localStorage.getItem(keyToGet) === null) {
        localStorage.setItem(keyToGet, keySetDefault)
        return localStorage.getItem(keyToGet)
      } else {
        return localStorage.getItem(keyToGet)
      }
      
    } else {
      //console.log('we are running on the server');
    }
    
}
export function getStorageItem(keyToGet){
  if (typeof window !== 'undefined') {
    //console.log('we are running on the client')
    localStorage.getItem(keyToGet)
    
  } else {
    //console.log('we are running on the server');
  }
}

export function updateStorageItem(keyToSet, keyItemValue){
  if (typeof window !== 'undefined') {
    //console.log('we are running on the client')

    try {
      localStorage.setItem(keyToSet, keyItemValue)
    } catch (error) {
      console.log(error)
    }

  } else {
    //console.log('we are running on the server');
  }
}