export const defaultTheme ={
    id: "T_001",
    name: "Default",
    colors: {
      //Colour Pallet
      PrimaryColor: "#27241D",
      SecoundaryColor: "#423D33",
      TertiaryColor: "#504A40",
      ForthColor: "#625D52",
      FifthColor: "#857F72",
      
      text: {
        //Typograhy Colours
        textColourPrimary: "#FAF9F7",
        textColourSecoundary: "#E8E6E1",
        textColourTertiary: "#D3CeC4",
      },

      link:{
        text: "",
        opacity: 0.5,
      },
    },

    font: "Ubuntu" //Font Name
  }

export const lightTheme = {
    id: "T_002",
    name: "Light",
    colors: {
      //Colour Pallet
      PrimaryColor: "#ffffff",
      SecoundaryColor: "#fafafa",
      TertiaryColor: "#e3e3e3",
      ForthColor: "#d1d1d1",
      FifthColor: "#b9b9b9",
      
      text: {
        //Typograhy Colours
        textColourPrimary: "#030303",
        textColourSecoundary: "#0e0e0e",
        textColourTertiary: "#141414",
      },

      link:{
        text: "",
        opacity: 0.9,
      },
    },

    font: "Ubuntu" //Font Name
  }

export const darkTheme = {
    id: "T_003",
    name: "Dark",
    colors: {
      //Colour Pallet
      PrimaryColor: "#030303",
      SecoundaryColor: "#0e0e0e",
      TertiaryColor: "##181818",
      ForthColor: "#202020",
      FifthColor: "#2b2b2b",
      
      text: {
        //Typograhy Colours
        textColourPrimary: "#f4f4f4",
        textColourSecoundary: "#ececec",
        textColourTertiary: "#cecece",
      },

      link:{
        text: "",
        opacity:0,
      },
    },

    font: "Ubuntu" //Font Name
  }

