import React from 'react'
import { Link } from "gatsby"
import styled from "styled-components"

const UlStyledMenu = styled.ul`
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    align-content: center;
    width: 100%;
`

function menu({children}) {
    return (
        <div style = {{width: "100%"}}>
            <UlStyledMenu>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/cv">Requests & CV</Link></li>
                <li><Link to="/about">About</Link></li>
                {children}
            </UlStyledMenu>
        </div>
    )
}

export default menu
