import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Menu from "./menu"
import { getStorageItem } from "../Scripts/LocalStorage"
import styled from "styled-components"
import Divider from "./components-ui/Divider"

const HeaderWrapper = styled.header`
    background-color: ${props => props.theme.colors.SecoundaryColor};
    margin-bottom: 0;
`
const HeaderThemeButton = styled.button`
    background-color: ${props => props.theme.colors.TertiaryColor};
    :hover{
      background-color: ${props => props.theme.colors.ForthColor};
    }
    color: ${props => props.theme.colors.text.textColourSecoundary};
    padding: 0.5rem;
    border:none;
    border-radius: 10px;
    cursor: pointer;
`
const HeaderContentFlex = styled.div`
    margin: 0 auto;
    max-width: 1500px;
    padding: 1rem 1.00rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    @media only screen and (max-width: 400px) {
      grid-template-columns: repeat(2, 1fr);
      strong{
        display: none;
      }
    }
`

const Header = (props) => {
  return (
    <HeaderWrapper>
      <HeaderContentFlex>
        <strong>Akeem OB</strong>
        <Menu>
        </Menu>
        <HeaderThemeButton onClick = {() => props.onThemeChange()}>Theme Equals {getStorageItem("theme")}</HeaderThemeButton>
      </HeaderContentFlex>
    <Divider/>
    </HeaderWrapper>
  )
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
